import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const LoaderHtml = ({ loading }) => {
	return (
		<div className="flex justify-center items-center min-h-screen">
			<ClipLoader color="#36d7b7" loading={loading} size={50} />
		</div>
	);
};

export default LoaderHtml;
