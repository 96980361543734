import React, { useState } from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { ServiceWrapper } from "../service/globalApiService";
import environment from '../environment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Signin = ({sendDataToParent}) => {
    const service = new ServiceWrapper();
    const [isOpen, setIsOpen] = useState(true);
    const { register, handleSubmit, formState: { errors } } = useForm();

    // Function to close the popup
    const closePopup = () => {
        setIsOpen(false);
        sendDataToParent(false);
    };

    const onSubmit = (data) => {
        console.log("This is member form data:", data);
        // sendDataToParent(data)
        service.callPost((environment.apiUrl)+'login',data)
        .then((response) =>{
               console.log("this is response",response);
               toast.success(response.message);
               setIsOpen(false);
               sendDataToParent(true);

        })
        .catch(error => {
            if(error.status == '422'){
                  toast.error("Email or Password is wrong");
            } 
            console.log('Error fetching events:', error);

          });
        // payment(data);
  }

  return (
    <div>
        {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative w-[40%] bg-white border-[10px] border-black p-[50px] rounded-3xl shadow-lg">
                        {/* Close Icon */}
                        <button
                            onClick={closePopup}
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl focus:outline-none"
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>

                        <h2 className="text-4xl font-bold mb-4 text-center">Sign In </h2>
                        <p className="text-gray-600 mb-8 text-[25px] leading-[1.5] font-semibold text-center">
                            Sign In in order to complete your payment and book your slot
                        </p>
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                            <input
                                id="email"
                                {...register('email', { 
                                    required: 'Email is required', 
                                    pattern: {
                                      value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                                      message: 'Invalid email address'
                                    }
                                  })}
                                type="email"
                                placeholder="Email"
                                className="w-full h-12 px-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                            />
                               {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>} 
                            <input
                                id="password"
                                type="password"
                                placeholder="Password"
                                {...register('password', { required: 'Company Name is required' })}
                                className="w-full h-12 px-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                            />
                            {errors.password && <p style={{ color: 'red' }}>{errors.password.message}</p>} 
                            <div className='w-fit mx-auto'>
                            <button
                                type="submit"
                                className="w-[200px] h-12 rounded-full bg-[#c34569] text-white font-semibold hover:bg-pink-600 transition duration-200"
                            >
                                Sign In
                            </button>
                            </div>
                        </form>

                        <p className="mt-6 text-center text-gray-600">
                            <a href="#" className="text-black text-[22px] font-semibold underline">Click here if not a member</a>
                        </p>
                        
                    </div>
                </div>
            )}
            <ToastContainer/>
    </div>
  )
}

export default Signin