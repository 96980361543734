import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faBriefcase, faBuilding, faLightbulb } from '@fortawesome/free-solid-svg-icons';

const MentorCard = ({ mentor, onNavigate }) => {
	const { id, image, name, designation, company_name, area_of_interest } = mentor;

	return (
		<div className="team-card w-full sm:w-[calc(100%/2)] md:w-[calc(100%/4)] p-4">
			<div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
				<div
					className="relative cursor-pointer"
					onClick={() => onNavigate(id)}
				>
					<img
						src={image}
						alt={name}
						className="w-full h-[300px] object-cover"
					/>
					<div className="absolute inset-0 bg-black bg-opacity-60 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300">
						<div className="flex gap-4 text-white">
							<a href="#" className="hover:text-blue-500">
								<FontAwesomeIcon icon={faLinkedin} size="lg" />
							</a>
							<a href="#" className="hover:text-blue-400">
								<FontAwesomeIcon icon={faTwitter} size="lg" />
							</a>
							<a href="#" className="hover:text-blue-700">
								<FontAwesomeIcon icon={faFacebook} size="lg" />
							</a>
						</div>
					</div>
				</div>
				<div className="p-5 text-left">
					<h3 className="text-xl font-bold mb-2">{name}</h3>
					<p className="text-gray-700 text-sm flex items-center pb-2 gap-2">
						<FontAwesomeIcon icon={faBriefcase} />
						{designation}
					</p>
					<p className="text-gray-700 text-sm font-medium flex pb-2 items-center gap-2">
						<FontAwesomeIcon icon={faBuilding} />
						{company_name}
					</p>
					<p className="text-gray-700 text-sm italic flex items-center pb-2 gap-2">
						<FontAwesomeIcon icon={faLightbulb} />
						{area_of_interest}
					</p>
				</div>
			</div>
		</div>
	);
};

export default MentorCard;
