import React, { useEffect, useState } from 'react'
import { ServiceWrapper } from "../service/globalApiService";
import { useNavigate } from "react-router-dom";
import MentorCard from '../components/MentorCard';
import LoaderHtml from '../components/LoaderHtml';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Mentors = () => {

  const service = new ServiceWrapper();
  let navigate = useNavigate();
  const [mentors, setMentors] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    service.mentorslisting()
      .then((response) => {
        setMentors(response.data);
      })
      .catch((error) => {
        toast.error('Some thing went wrong');
        console.log('Error fetching events:', error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false whether the API call succeeds or fails
      });
  }, []);


  const handleMentorPageNavigation = (id) => {
    let path = "/mentors-details/" + id;
    navigate(path);
  }

  if (loading) {
    return <LoaderHtml loading={loading} />;
  }
  return (
    <div className="bg-gray-50 py-10">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8">Meet Our Team</h2>
        <div className="flex flex-wrap justify-center gap-6">
          {mentors.map((mentor) => (
            <MentorCard
              key={mentor.id}
              mentor={mentor}
              onNavigate={handleMentorPageNavigation}
            />
          ))}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Mentors