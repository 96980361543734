import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const EventCard = ({ event, onNavigate }) => {
	const { id, image, name, category } = event;

	return (
		<div className='card-box w-full sm:w-[calc(100%/2)] md:w-[calc(100%/3)] p-2 h-auto' key={id}>
			<div className="bg-white h-full flex flex-col justify-between rounded-xl shadow-xl">
				<div className="thumbnail relative h-[300px]" onClick={() => onNavigate(id)}>
					<img src={image} alt={name} className="w-full rounded-t-xl h-full object-cover" />
					<div className="bookmark-icon absolute top-2 right-2">
						{/* Add your bookmark icon here */}
					</div>
				</div>
				<div className="card-content mt-2 p-4 gap-2 flex flex-col">
					<h4 className="font-bold text-lg">
						<Link to={`/event/${id}`} className="hover:underline">{name}</Link>
					</h4>
					<div className="text-gray-400 flex gap-2"><FontAwesomeIcon icon={faCalendarAlt} className="text-gray-400 text-xl" /> {event.event_date}</div>
				</div>
				<div className="mt-2 block">
					{/* Placeholder for an icon */}
					<div className="icon-placeholder mr-2">
						{/* Insert your icon here */}
					</div>
					<div className="text-gray-500 p-4 border-t-[1px] w-full border-black block">{category.name}</div>
				</div>
			</div>
		</div>
	);
};

export default EventCard;
