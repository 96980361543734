import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUserTie, faCalendar, faUsers, faPersonChalkboard, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import environment from '../environment';
import { useNavigate,useParams   } from "react-router-dom";
import { ServiceWrapper } from "../service/globalApiService";
import Modal from 'react-modal';
import ClipLoader from 'react-spinners/ClipLoader'; 
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MentorsDetails = () => {
  const service = new ServiceWrapper();
  let navigate = useNavigate(); 
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [razorpayLoaded, setRazorpayLoaded] = useState(false);  
  const [mentor, setMentorData] = useState({});
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [htmlContent, setHtmlContent] = useState('');
  const [sessionContent, setSessionContent] = useState('');
  const [userInfo, setUserInfo] = useState({});

  // Parsing the query parameters
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    service.callGet((environment.apiUrl)+'mentors/'+id)
    .then((response) =>{
      setLoading(false);
      if(response.data.status == true){
      setMentorData(response.data.data);
      setHtmlContent(response.data.data.description);
      setSessionContent(response.data.data.session_content);
      }else{
        toast.error("Mentor not found");
        navigate("/mentors")
      }
    })
    .catch(error => {
      toast.error("Mentor not found");
      console.log('Error fetching events:', error)
      navigate("/mentors");
    });

    const loadRazorpayScript = () => {
      return new Promise((resolve, reject) => {
        if (window.Razorpay) {
          resolve(window.Razorpay);
        } else {
          const script = document.createElement('script');
          script.src = "https://checkout.razorpay.com/v1/checkout.js";
          script.onload = () => resolve(window.Razorpay);
          script.onerror = () => reject('Failed to load Razorpay script');
          document.body.appendChild(script);
        }
      });
    };

    loadRazorpayScript()
      .then(() => {
        console.log("Razorpay SDK loaded successfully");
        setRazorpayLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading Razorpay SDK:", error);
        setRazorpayLoaded(false);
      });
  }, []);

   // Open Modal function
   const openModal = () => setIsOpen(true);

   // Close Modal functio
   const closeModal = () => setIsOpen(false);

  const payment =(userInfo) =>{
    // console.log("this is user data",userInfo);
    let testingPayload = {
      "name":userInfo.name,
      "email":userInfo.email,
      "company_name":userInfo.companyName,
      "designation":userInfo.designation,
      "mentor_id":id
    }
    console.log("this is user data",testingPayload);
    setLoading(true);
    service.callPost((environment.apiUrl)+'mentor-appointment-payment', testingPayload)
    .then((response) =>{
      console.log("this is resposne",response.data);
      closeModal();
      setLoading(false);
      
      if (window.Razorpay) {
        
      const options = {
        "key": "rzp_test_jGGNXzAI6NLpiO", 
        "amount": mentor?.hourly_price*100,
        "currency": "INR",
        "entity": "order",
        "order_id": response.order_id,
        handler: function (response) {
          toast.success("Payment done successFully");
        }
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();

    rzp1.on("payment.failed", function (response) {
        alert("Payment Failed! " + response.error.description);
    });
  }
      // setMentors(response.data);
    })
    .catch(error => console.log('Error fetching events:', error));
  }

  const bannerImage='https://ladieswholead.in/wp-content/uploads/2023/11/WhatsApp-Image-2023-11-07-at-10.37.50-AM-e1710752973237.jpeg';


  const onSubmit = (data) => {
        console.log("This is member form data:", data);
        payment(data);
  }

  return (
    <div>
      {/* Breadcrumb Banner */}
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <ClipLoader color="#36d7b7" loading={loading} size={50} />
          <ToastContainer />
        </div>
      ) : (
        <>
        <div
        className="relative h-[40vh] bg-cover bg-top"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        <div className="absolute inset-x-0 top-[100%] translate-y-[-40%] flex justify-center">
          <img
            src={mentor.image}
            alt={mentor.name}
            className="w-56 h-56 object-cover bg-center rounded-lg border-4 border-white shadow-lg"
          />
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 mt-60 grid grid-cols-1 lg:grid-cols-3 gap-10">
        {/* Left Section */}
        <div className="lg:col-span-2">
          <div className="text-left">
            <p className="text-gray-700 text-2xl flex items-center gap-3">
              <FontAwesomeIcon icon={faEnvelope} className="text-blue-500" />
              {mentor.title}
            </p>

            <p className="text-gray-500 text-md mt-2 flex items-center gap-3">
              <FontAwesomeIcon icon={faUserTie} className="text-green-500" />
              {mentor.designation}
            </p>
            <div className="mt-5">
              <h3 className="text-xl font-bold border-b pb-2 mb-4">
              About Mentor:
              </h3>
              <p className="text-gray-600">{mentor.short_summary}</p>
            </div>
            <div className="mt-5">
            <h3 className="text-xl font-bold border-b pb-2 mb-4">
              Description:
              </h3>
              <p className="text-gray-600" dangerouslySetInnerHTML={{ __html: htmlContent }}></p>
            </div>
          </div>

          {/* <div className="mt-10">
            <h3 className="text-xl font-bold border-b pb-2 mb-4">
              What I Expect from a Mentee:
            </h3>
            <p className="text-gray-600">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at magna ac magna sagittis suscipit. Nulla facilisi. Morbi non auctor lectus. Aliquam erat volutpat.
            </p>
          </div> */}

          {/* <div className="mt-10">
            <h3 className="text-xl font-bold mb-4">More About This Mentor:</h3>
            <ul className="list-none text-gray-600">
              <li className="flex items-center gap-3 mb-2">
                <FontAwesomeIcon icon={faCalendar} className="text-blue-500" />
                Member Since: {mentor.mentorSince}
              </li>
              <li className="flex items-center gap-3 mb-2">
                <FontAwesomeIcon icon={faUsers} className="text-green-500" />
                Currently Mentoring: {mentor.currentMentees} Person
              </li>
              <li className="flex items-center gap-3">
                <FontAwesomeIcon icon={faPersonChalkboard} className="text-purple-500" />
                Mentored: {mentor.pastMentees} People in the Past
              </li>
            </ul>
          </div> */}
        </div>

        {/* Right Sidebar */}
        <div className="flex flex-col gap-[40px]">
          <div>
            <h3 className="text-xl font-bold mb-6">Book a Session</h3>
            <div className="p-6 bg-[#c44569] border text-white rounded-lg shadow-md">
              <div className="flex items-center gap-3 mb-4">
                <FontAwesomeIcon icon={faPhone} className="text-white text-lg" />
                <h4 className="text-lg font-semibold">Quick Call</h4>
              </div>
              <p className="text-white text-2xl mb-3">
                <span className="font-bold">{mentor.hourly_price} ₹ / hr</span>
              </p>
              
              <div className="text-white text-md space-y-2" dangerouslySetInnerHTML={{ __html: sessionContent }}></div>
              <button
                className="mt-6 w-full bg-[#faba5a] text-[#c44569] font-bold py-2 rounded-md shadow hover:bg-black hover:text-white transition"
                onClick={openModal}
              >
                Book Now
              </button>
            </div>
          </div>

          <div>
            <div className="p-6 bg-[#f4f4f4] border rounded-lg shadow-md">
              <div className="flex items-center gap-3 mb-4">
                <FontAwesomeIcon icon={faUserTie} className="text-black text-lg" />
                <h4 className="text-lg font-semibold">3 Month Package </h4>
              </div>
              <p className="text-gray-700 text-2xl mb-3">
                <span className="font-bold">₹ 3510</span>
              </p>
              <ul className="text-gray-600 text-md space-y-2">
                <li>You'll get 6 calls will be of 1 hour</li>
                <li>Duration of each call will be of 1 hour</li>
                <li>You will receive the subscription for Mentorkart platform.</li>
              </ul>
              <button className="mt-6 w-full bg-[#c44569] text-white py-2 rounded-md shadow hover:bg-black hover:text-white transition"  onClick={openModal}>
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      </>
         )}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Booking Confirmation"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Booking Confirmation</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="personal-details mt-5">
        <div className="flex flex-row gap-3 mb-10">
           <div className='max-w-[50%] flex gap-3 w-full'>
             <label className="block">Name:</label>
             <span>{mentor.name}</span>
          </div>
          <div className='max-w-[50%] flex gap-3 w-full'>
             <label className="block">Hourly Price</label>
             <span>{mentor.hourly_price} ₹ / hr</span>
          </div>
        </div>
        <h2 className="font-bold mb-10">Personal Details</h2>
        <div className="flex flex-row gap-3 mb-10">
          <div className='max-w-[50%] flex gap-3 w-full'>
             <select className='max-w-[20%] w-full outline-none border-b-[1px] border-black' name="" id="">
              <option value="">Mr.</option>
              <option value="">Ms</option>
            </select>
            <input className='max-w-[80%] w-full outline-none border-b-[1px] border-black'
            id= 'name'
            type = 'text'
            placeholder="Name"
            {...register('name', { required: 'Name is required' })}
             />
               {errors.name && <p style={{ color: 'red' }}>{errors.name.message}</p>}
          </div>
          <div className='max-w-[50%] flex gap-3 w-full'>
          <input className='max-w-[100%] h-[40px] w-full outline-none border-b-[1px] border-black' 
             type="email" 
             id="email"
             {...register('email', { 
               required: 'Email is required', 
               pattern: {
                 value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                 message: 'Invalid email address'
               }
             })}
             placeholder='email' />
              {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>} 
          </div>
        </div>
       

          <div className='max-w-[100%] flex gap-3 w-full'>
          <input className='max-w-[80%] h-[40px] w-full outline-none border-b-[1px] border-black'
             type="text"
             id= 'companyName'
             placeholder='Company Name'
            {...register('companyName', { required: 'Company Name is required' })}
             />
             {errors.companyName && <p style={{ color: 'red' }}>{errors.companyName.message}</p>} 
            <input className='max-w-[80%] h-[40px] w-full outline-none border-b-[1px] border-black'
             type="text"
             id= 'designation'
             placeholder='Designation' 
             {...register('designation', { required: 'Designation is required' })}
              />
              {errors.designation && <p style={{ color: 'red' }}>{errors.designation.message}</p>} 
          </div>
          <button  type='submit' className="confirm-btn">
            Payment
          </button>
          <button onClick={closeModal} className="cancel-btn">
            Cancel
          </button>
        
        </div>
        </form>
        {/* <div> */}
          
        {/* </div> */}
      </Modal>
    </div>
  );
};

export default MentorsDetails;
