import React, { useEffect, useState } from 'react';
import { ServiceWrapper } from "../service/globalApiService";
import LoaderHtml from '../components/LoaderHtml';
import EventCard from '../components/EventCard';
import { useNavigate } from "react-router-dom";

const UpcomingEvents = () => {
  const service = new ServiceWrapper();
  let navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    service.eventslisting()
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.log('Error fetching events:', error);
      })
      .finally(() => {
        setLoading(false); // Ensure loading is set to false in any case
      });
  }, []);

  const handleDetailPageNavigation = (id) => {
    let path = "/event/" + id;
    navigate(path);
  };

  if (loading) {
    return <LoaderHtml loading={loading} />;
  }

  return (
    <div className='bg-gray-50'>
      <div className='container mx-auto'>
        <div className="title my-5">
          <h2 className='text-4xl'>Upcoming Events</h2>
        </div>
        <div className='flex flex-wrap'>
          {events.map((event) => (
            <EventCard
              key={event.id}
              event={event}
              onNavigate={handleDetailPageNavigation}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvents;
