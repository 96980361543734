import axios from "axios";
import environment from '../environment';

export class ServiceWrapper {

  callGet = async (url) => {
    return await axios.get(url);
  };
  callPost = async (url, body) => {
    return  await axios.post(url, body);
  };

  mentorslisting = async () => {
    var url = (environment.apiUrl) + 'mentors';
    return await axios.get(url);
  };
  eventslisting = async () => {
    var url = (environment.apiUrl) + 'events';
    return await axios.get(url);
  };
  eventDetail = async (id) => {
    var url = (environment.apiUrl) + 'events/'+id;
    return await axios.get(url);
  };
  // callPut = (url: string, body: any) => {
  //   return axios.put(url, {
  //     data: body,
  //   });
  // };
  // callDelete = (url: string, body: any) => {
  //   return axios.delete(url, {
  //     data: body,
  //   });
  // };
  // callGetWithParams = (url: string, params: any) => {
  //   return axios.get(url, {
  //     params: params,
  //   });
  // };
  // uploadMedia = (base64Image: string) => {
    
  // };
}
