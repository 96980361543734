import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import Signin  from '../components/Signin';
import Modal from 'react-modal';
import { Link, useNavigate } from "react-router-dom";
Modal.setAppElement('#root');


const Header = () => {
  let navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const imagePath = require("../assets/images/logo-white.webp");
  const [userLoginInfo, setloginInfo] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const divStyle = {
    hight:"323",
    backgroundColor: 'lightgray',
  };
  const  loginModel = () =>{
       setIsOpen(true);
  }
  const homePageNavigate = () => {
    let path = "/";
    navigate(path);
  };


const handleDataFromChild = (data) => {
  console.log("this is data",data)
  if(data){
      setloginInfo(true);
      homePageNavigate();
  }
  closeModal(); 
};
  return (
    <>
    <section className="bg-black">
      <div className="container mx-auto px-4 sm:px-6">
        <header className="flex items-center justify-between p-5 text-white relative">
          {/* Logo */}
          <div className="flex items-center cursor-pointer" onClick={() => homePageNavigate()}>
            <img src={imagePath} alt="Logo" className="h-12 mr-8 cursor-pointer" />
          </div>

          {/* Menu Items */}
          <nav className="hidden lg:flex space-x-6 justify-center items-center">
            <Link to={"/membership"} className="hover:text-blue-400">Membership</Link>
            <Link to={"/upcoming-events"} className="hover:text-blue-400">Events</Link>
            <Link href="#benefits" className="hover:text-blue-400">Benefits</Link>
            <Link href="#partnership" className="hover:text-blue-400">Partnership</Link>
            <Link to={"/mentors"} className="hover:text-blue-400">Mentors</Link>
          </nav>

          {/* Right Side - Search Icon and Buttons */}
          <div className="flex items-center justify-center space-x-4">
            {/* Sliding Search Box */}
            <div className="relative">
              <input
                type="text"
                placeholder="Search"
                className={`absolute top-0 h-full right-[20px] ${isSearchOpen ? 'w-48 px-2 py-1' : 'w-0'
                  } bg-white text-black placeholder-gray-500 rounded-l transition-all duration-300 ease-in-out focus:outline-none`}
                style={{
                  overflow: 'hidden',
                  opacity: isSearchOpen ? 1 : 0,
                }}
              />
              <button
                onClick={() => setIsSearchOpen(!isSearchOpen)}
                className="text-white focus:outline-none relative"
              >
                <FaSearch />
              </button>
            </div>

            {/* Login and Signup Buttons */}
            { !userLoginInfo ? (
            <>
            <button className="px-6 py-2 rounded-full bg-[#c44569] uppercase" onClick={loginModel}>Login</button>
            <button className="px-6 py-2 bg-transparent border-[2px]
             border-[#c44569] rounded-full uppercase"><Link to={"/member-details"}>Signup</Link></button>
           </>
            ):(
              <>
                  <button className="px-6 py-2 rounded-full bg-[#c44569] uppercase" >Logout</button>
              </>
            )
            }
          </div>
        </header>
      </div>
    </section>

    <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        // contentLabel="Booking Confirmation"
        className="modal-content"
        style={divStyle}
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
           <Signin sendDataToParent={handleDataFromChild} />
      </Modal>
    </>
  );
};

export default Header;
