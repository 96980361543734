import * as React from "react";
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';



const Memberdetails =  () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [planDetails, setPlanInfo] = useState({});
  const [showConfirmationPage, setConfirmationPage] = useState(false); // Using useState for this
  const [ customerInfo , setCustomerInfo ] = useState();
  const [loading, setLoading] = useState(false);
  const [razorpayLoaded, setRazorpayLoaded] = useState(false);  

  // Fetch plan details from localStorage once the component is mounted
  useEffect(() => {
    var savedPlan = localStorage.getItem('selectedPlan');
    if (savedPlan) {
      setPlanInfo(JSON.parse(savedPlan)); // Set the plan info from localStorage
    }

    const loadRazorpayScript = () => {
      return new Promise((resolve, reject) => {
        if (window.Razorpay) {
          resolve(window.Razorpay);
        } else {
          const script = document.createElement('script');
          script.src = "https://checkout.razorpay.com/v1/checkout.js";
          script.onload = () => resolve(window.Razorpay);
          script.onerror = () => reject('Failed to load Razorpay script');
          document.body.appendChild(script);
        }
      });
    };

    loadRazorpayScript()
      .then(() => {
        console.log("Razorpay SDK loaded successfully");
        setRazorpayLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading Razorpay SDK:", error);
        setRazorpayLoaded(false);
      });
  }, []); // Empty dependency array to ensure it runs once on mount

  const onSubmit = (data) => {
    // console.log("This is member form data:", data);
    let userInfo = data;
    userInfo.PlanId = planDetails.id;
    userInfo.PlanName = planDetails.title;
    userInfo.price = planDetails.fee;
    // userInfo.PlanId = 1
    // userInfo.PlanName = "4"
    // userInfo.price = "4"
   
    setConfirmationPage(true); // Now you can update the state here
    setCustomerInfo(userInfo);

  }

  const handlePlanPayment = async () => {
    if (window.Razorpay) {
        setLoading(true);
        
        const options = {
            "key": "ByoeyOlE7sUBk78PmMiZlMSA", 
            "amount": 50000,
            "amount_due": 50000,
            "amount_paid": 0,
            "attempts": 0,
            "created_at": 1733329332,
            "currency": "INR",
            "entity": "order",
            "id": "order_PTCKhNbibYDWG",
            "notes": [],
            "offer_id": null,
            "receipt": "order_rcptid_11",
            "status": "created",
            handler: function (response) {
                alert("Payment successful! Payment ID: " + response.razorpay_payment_id);
            },
            prefill: {
                name: "Your Name",
                email: "youremail@example.com",
                contact: "1234567890",
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();

        rzp1.on("payment.failed", function (response) {
            alert("Payment Failed! " + response.error.description);
        });
        setLoading(false);
    } else {
        alert("Razorpay SDK not loaded. Please try again later.");
    }
};

  return (
    <div className='container mx-auto'>
        <div className="flex p-5 gap-5">
        <div className="flex flex-col w-2/5 pr-5 relative">
      <ul className="list-none pl-0 relative">
        <li className="flex items-center mb-20">
          <span className="h-5 w-5 bg-pink-500 rounded-full mr-3"></span>
          <h3 className="font-bold text-xl">Membership Details</h3>
        </li>
        <div className="absolute left-2 top-[6px] h-[44%] w-1 bg-pink-500"></div>

        <li className="flex items-center mb-20">
          <span className="h-5 w-5 border-2 border-gray-500 rounded-full mr-3 bg-transparent"></span>
          <h3 className="font-bold text-xl">Confirm Details</h3>
        </li>
        <div className="absolute left-2 top-[131px] h-[37%] w-1 bg-gray-500"></div>

        <li className="flex items-center">
          <span className="h-5 w-5 border-2 border-gray-500 rounded-full mr-3 bg-transparent"></span>
          <h3 className="font-bold text-xl">Payment</h3>
        </li>
      </ul>
    </div>
      {/* Left Section: Membership Steps */}
      {showConfirmationPage ?
        <div className="w-3/5">
         <div className="mb-10">
          <label className="block">Membership Name:</label>
          <span>{customerInfo.title}</span>
        </div>
  
        <div className="mb-10">
          <label className="block">Membership fee:</label>
          <span>{customerInfo.price}</span>
        </div>

        <div className="mb-10">
          <label className="block">First Name:</label>
          <span>{customerInfo.firstName}</span>
        </div>
  
        <div className="mb-10">
          <label className="block">Last Name:</label>
          <span>{customerInfo.lastName}</span>
        </div>


        <div className="mb-10">
          <label className="block">Moblie Number:</label>
          <span>{customerInfo.mobileNo}</span>
        </div>
  
        <div className="mb-10">
          <label className="block">Email id :</label>
          <span>{customerInfo.email}</span>
        </div>

        <div className="mb-10">
           <button onClick={(e) => {handlePlanPayment()}}>Payment</button>
         </div>
      </div>
    :
    <div>
   

    <div className="w-3/5">
      <h2 className="text-2xl font-bold mb-10">Membership Details</h2>
      <div className="mb-10">
        <label className="block">Membership Name:</label>
        <span>{planDetails.title}</span>
      </div>

      <div className="mb-10">
        <label className="block">Membership fee:</label>
        <span>{planDetails.price}</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="personal-details mt-5">
        <h2 className="text-2xl font-bold mb-10">Personal Details</h2>
        <div className="flex flex-row gap-3 mb-10">
          <div className='max-w-[50%] flex gap-3 w-full'>
            <select className='max-w-[20%] w-full outline-none border-b-[1px] border-black' name="" id="">
              <option value="">Mr.</option>
              <option value="">Ms</option>
            </select>
            <input className='max-w-[80%] w-full outline-none border-b-[1px] border-black'
            id= 'firstName'
            type = 'text'
            placeholder="First Name"
            {...register('firstName', { required: 'First Name is required' })}
             />
            
          </div>
          {errors.firstName && <p style={{ color: 'red' }}>{errors.firstName.message}</p>}
          <input className='max-w-[45%] w-full outline-none border-b-[1px] border-black'
            id= 'lastName'
            type = 'text'
            {...register('lastName', { required: 'Last Name is required' })}
            placeholder='Last Name' />
        </div>
         {errors.lastName && <p style={{ color: 'red' }}>{errors.lastName.message}</p>} 
        <div className="flex flex-row gap-3 mb-10">
          <div className='max-w-[50%] flex gap-3 w-full'>
            <select className='max-w-[20%] w-full outline-none border-b-[1px] border-black' name="" id="">
              <option value="">+91</option>
            </select>
            <input className='max-w-[80%] w-full outline-none border-b-[1px] border-black'
              id = 'mobileNo'
              type="tel"
              {...register('mobileNo', { required: 'Last Name is required' ,pattern: {
                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                message:"Only number is allow"
              } })}
              placeholder='9876543210' />
          </div>
          {errors.mobileNo && <p style={{ color: 'red' }}>{errors.mobileNo.message}</p>} 
          <div className='flex gap-3 max-w-[50%] w-full'>
            <label className='max-w-[40%] w-full font-bold' htmlFor="">Enter OTP</label>
            <div className='flex gap-3 max-w-[60%] w-full items-center text-center'>
              <input className='w-[25%] outline-none border-b text-center' type="text" placeholder='8' />
              <input className='w-[25%] outline-none border-b text-center' type="text" placeholder='1' />
              <input className='w-[25%] outline-none border-b text-center' type="text" placeholder='2' />
              <input className='w-[25%] outline-none border-b text-center' type="text" placeholder='9' />
            </div>
          </div>
        </div>
        <div className="company">
          <div className='flex gap-3 mb-10'>
            <input className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black' 
             type="email" 
             id="email"
             {...register('email', { 
               required: 'Email is required', 
               pattern: {
                 value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                 message: 'Invalid email address'
               }
             })}
             placeholder='email' />
              {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>} 
          </div>
          <div className='flex gap-4 mb-10'>
            <input className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black'
             type="text"
             id= 'companyName'
             placeholder='Company Name'
            {...register('companyName', { required: 'Company Name is required' })}
             />
             {errors.companyName && <p style={{ color: 'red' }}>{errors.companyName.message}</p>} 
            <input className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black'
             type="text"
             id= 'designation'
             placeholder='Designation' 
             {...register('designation', { required: 'Designation is required' })}
              />
              {errors.designation && <p style={{ color: 'red' }}>{errors.designation.message}</p>} 
          </div>
        </div>
        <div className="address flex gap-4 mb-10">
          <input className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black' 
          type="text"
          id= 'city'
          {...register('city', { required: 'city is required' })}
          placeholder='City' />
          {errors.city && <p style={{ color: 'red' }}>{errors.city.message}</p>}
          <input className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black'
           type="text" 

           placeholder='User name' />
        </div>
        <div className="flex gap-4 mb-10">
          <input className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black' 
          type="password" 
          id = 'password'
          {...register('password', { required: 'password is required' })}
          placeholder='Password'
           />
           {errors.password && <p style={{ color: 'red' }}>{errors.password.message}</p>}
          <input className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black' 
          type="password"
          id="confirmPassword"
          {...register('confirmPassword', {
            required: 'Confirm password is required',
            validate: (value) => value === document.getElementById('password').value || 'Passwords do not match'
          })} 
          placeholder='Confirm password' />
          {errors.confirmPassword && <p style={{ color: 'red' }}>{errors.confirmPassword.message}</p>}
        </div>
      </div>
      <div className="gst-details mt-5">
        <h2 className="text-2xl font-bold mb-10">GST Details</h2>
        <div className='flex gap-2 mb-3'>
          <input type="checkbox" />
          <label htmlFor="" className='font-bold'>I want to provide my GST Details(Optional)</label>
        </div>
        <div className='flex flex-row gap-4 mb-10'>
          <input className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black'
           type="text" 
           id='gstNumber'
           {...register('gstNumber', { required: 'password is required' })}
           placeholder='GST Number' />
           {errors.gstNumber && <p style={{ color: 'red' }}>{errors.gstNumber.message}</p>}
          <input className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black'
           type="text"
           id= 'companyName'
           {...register('companyName', { required: 'Company Name is required' })}
           placeholder='Company Name' />
            {errors.gstNumber && <p style={{ color: 'red' }}>{errors.gstNumber.message}</p>}
        </div>
        <div className='flex gap-4 mb-10'>
          <input className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black' 
          type="text" 
          id='addressLine1'
          {...register('addressLine1', { required: 'Address line one is required' })}
          placeholder='Address line 1 ' />
           {errors.addressLine1 && <p style={{ color: 'red' }}>{errors.addressLine1.message}</p>}
          <input className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black' 
          type="text" 
          id='addressLine2'
          {...register('addressLine2', { required: 'Address line two is required' })}
          placeholder='Address line 2 ' />
          {errors.addressLine2 && <p style={{ color: 'red' }}>{errors.addressLine2.message}</p>}
        </div>
        <div className='flex gap-4 mb-10'>
          <select className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black' 
          name="" 
          id="">
            <option value="">State</option>
          </select>
          <input className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black' 
          type="text"
          id='pinCode'
          {...register('pinCode', { required: 'Pin code is required' })}
          placeholder='Pincode' />
          {errors.pinCode && <p style={{ color: 'red' }}>{errors.pinCode.message}</p>}
        </div>
        <div className='flex gap-4 mb-10'>
          <select className='max-w-[50%] h-[40px] w-full outline-none border-b-[1px] border-black' name="" id="">
            <option value="">Country</option>
          </select>
        </div>
      </div>
      <button type="submit"> submit</button>
      </form>
    </div>
    </div>
    }
    </div>
    </div>
  )
}

export default Memberdetails